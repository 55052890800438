import React from 'react';
import { Button, Form, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import { FaInstagram, FaFacebook, FaTwitter, FaLine } from 'react-icons/fa';

const EditPanel = ({
    isEditMode,
    handleEditToggle,
    setBackgroundColor,
    homeTop,
    setHomeTop,
    logoFontSize,
    setLogoFontSize,
    setLogoFont,
    setLogoFontWeight,
    setLogoFontColor,
    setShadowOption,
    setShadowColor,
    nav,
    setNav,
    navFontSize,
    setNavFontSize,
    setNavFontColor,
    setNavFontFamily,
    setNavFontWeight,
    latestItems,
    setLatestItems,
    displayStyle,
    snsUrls,
    selectedSns,
    setSelectedSns,
    setDisplayStyle,
    iconSize,
    setIconSize,
    setSnsUrls
}) => {
    const CustomDropdownItem = ({ layoutNumber, currentLayout, onClick }) => (
        <Dropdown.Item
            onClick={onClick}
            style={{ backgroundColor: layoutNumber === currentLayout ? '#eee' : 'transparent' }}
        >
            レイアウト {layoutNumber}
        </Dropdown.Item>
    );
    const shadowOptions = [
        { name: 'なし', value: '0px 0px 0px' },
        { name: '軽い', value: '1px 1px 2px' },
        { name: '普通', value: '2px 2px 4px' },
        { name: '強い', value: '4px 4px 8px' },
    ];
    const latestItemsOptions = [
        { name: 'ギャラリー', value: 'gallery' },
        { name: 'カルーセル', value: 'carousel' },
        { name: 'リスト', value: 'list'},
        { name: '非表示', value: 'none' },
    ];
    const SnsOptions = [
        { label: 'Instagram', value: 'instagram', Icon: FaInstagram, defaultColor: '#E1306C' },
        { label: 'Facebook', value: 'facebook', Icon: FaFacebook, defaultColor: '#3b5998' },
        { label: 'Twitter', value: 'twitter', Icon: FaTwitter, defaultColor: '#1DA1F2' },
        { label: 'LINE', value: 'line', Icon: FaLine, defaultColor: '#00b900' },
    ];

    const handleSnsSelection = (value) => {
        setSelectedSns(prev => [...prev, value]);
    };

    const removeSnsSelection = (value) => {
        setSelectedSns(prev => prev.filter(sns => sns !== value));
        setSnsUrls(prev => ({
            ...prev,
            [value]: { url: '', validationMessage: '' }
        }));
    };
    // 仮の正規表現パターン（適宜修正してください）
    const patterns = {
        instagram: /^https?:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9_.]+\/?$/,
        facebook: /^https?:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9_.]+\/?$/,
        twitter: /^https?:\/\/(www\.)?x\.com\/[a-zA-Z0-9_]+\/?$/,
        line: /^https?:\/\/line\.me\/R\/ti\/p\/[a-zA-Z0-9]+$/,
    };

    const handleSnsUrlChange = (name, value) => {
        setSnsUrls(prev => ({
            ...prev,
            [name]: { ...prev[name], url: value, validationMessage: '' } // 一時的にバリデーションメッセージをクリア
        }));
    };
    
    const handleSnsUrlBlur = (name) => {
        const url = snsUrls[name].url;
        let validationMessage = '';
        if (!patterns[name].test(url)) {
            validationMessage = `有効な${name.charAt(0).toUpperCase() + name.slice(1)}のURLを入力してください。`;
        }
    
        setSnsUrls(prev => ({
            ...prev,
            [name]: { ...prev[name], validationMessage: validationMessage }
        }));
    };

    return (
        <div className={`edit-panel bg-white shadow-lg ${isEditMode ? 'show' : ''}`} style={{ width: '320px', zIndex: 1050 }}>
            <div className="p-4">
                {/* <h3 className='mb-3'>
                    理想のデザインを、<br />
                    たったの一手間で
                </h3> */}
                <Row className='mt-2'>
                    <Col>
                        <h4 className=''>
                            トップページの設定
                        </h4>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>背景色</Form.Label>
                        <Form.Control type="color" onChange={(e) => setBackgroundColor(e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>トップのレイアウト</Form.Label>
                        <DropdownButton id="dropdown-basic-button" title={`レイアウト ${homeTop} 選択中`}>
                            {[1, 2, 3, 4, 5, 6].map((layoutNumber) => (
                                <CustomDropdownItem
                                    key={layoutNumber}
                                    layoutNumber={layoutNumber}
                                    currentLayout={homeTop}
                                    onClick={() => setHomeTop(layoutNumber)}
                                />
                            ))}
                        </DropdownButton>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>ロゴのフォントサイズ: {logoFontSize}</Form.Label>
                        <Form.Control
                            type="range"
                            min="0.5" // 最小値
                            max="8"   // 最大値
                            step="0.1" // ステップサイズ
                            className="custom-range"
                            value={logoFontSize.replace('rem', '')} // 現在のフォントサイズ
                            onChange={(e) => setLogoFontSize(`${e.target.value}rem`)} // フォントサイズの更新
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>ロゴのフォント</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={(e) => setLogoFont(e.target.value)}>
                            <option value="Georgia">Georgia</option>
                            <option value="Courier New">Courier New</option>
                            <option value="Comic Sans MS">Comic Sans MS</option>
                            <option value="Impact">Impact</option>
                            <option value="Times New Roman">Times New Roman</option>
                            <option value="Arial">Arial</option>
                            <option value="Verdana">Verdana</option>
                            <option value="Lucida Console">Lucida Console</option>
                            {/* <option value="Tahoma">Tahoma</option>
                            <option value="Trebuchet MS">Trebuchet MS</option> */}

                        </Form.Select>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>ロゴの太さ</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={(e) => setLogoFontWeight(e.target.value)}>
                            <option value="bold">太字</option>
                            <option value="normal">普通</option>
                            {/* <option value="lighter">細字</option> */}
                        </Form.Select>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>ロゴの色</Form.Label>
                        <Form.Control type="color" onChange={(e) => setLogoFontColor(e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>ロゴの影</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={(e) => setShadowOption(e.target.value)}>
                            {shadowOptions.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>ロゴの影の色</Form.Label>
                        <Form.Control type="color" onChange={(e) => setShadowColor(e.target.value)} />
                    </Col>
                </Row>

                <Row className="pt-4">
                    <Col>
                        <h4>
                            ナビゲーションの設定
                        </h4>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>ナビゲーションのレイアウト</Form.Label>
                        <DropdownButton id="dropdown-nav-layout-selector" title={`レイアウト ${nav} 選択中`}>
                            {[0, 1, 2].map((layoutNumber) => (
                                <CustomDropdownItem
                                    key={layoutNumber}
                                    layoutNumber={layoutNumber}
                                    currentLayout={nav}
                                    onClick={() => setNav(layoutNumber)}
                                />
                            ))}
                        </DropdownButton>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>ナビゲーションのフォントサイズ: {navFontSize}</Form.Label>
                        <Form.Control
                            type="range"
                            min="0.6" // 最小値
                            max="3"   // 最大値
                            step="0.1" // ステップサイズ
                            className="custom-range"
                            value={navFontSize.replace('rem', '')} // 現在のフォントサイズ
                            onChange={(e) => setNavFontSize(`${e.target.value}rem`)} // フォントサイズの更新
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>ナビゲーションの色</Form.Label>
                        <Form.Control type="color" onChange={(e) => setNavFontColor(e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>ナビゲーションのフォント</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={(e) => setNavFontFamily(e.target.value)}>
                            <option value="Georgia">Georgia</option>
                            <option value="Courier New">Courier New</option>
                            <option value="Comic Sans MS">Comic Sans MS</option>
                            <option value="Impact">Impact</option>
                            <option value="Times New Roman">Times New Roman</option>
                            <option value="Arial">Arial</option>
                            <option value="Verdana">Verdana</option>
                            <option value="Lucida Console">Lucida Console</option>
                            {/* <option value="Tahoma">Tahoma</option>
                            <option value="Trebuchet MS">Trebuchet MS</option> */}
                        </Form.Select>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>ナビゲーションの太さ</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={(e) => setNavFontWeight(e.target.value)}>
                            <option value="normal">普通</option>
                            <option value="bold">太字</option>
                            {/* <option value="lighter">細字</option> */}
                        </Form.Select>
                    </Col>
                </Row>

                <Row className="pt-4">
                    <Col>
                        <h4>
                            最新アイテムの表示
                        </h4>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>最新アイテムのレイアウト</Form.Label>
                        <DropdownButton id="dropdown-latest-items" title={`${latestItems}`}>
                            {latestItemsOptions.map((option, index) => (
                                <Dropdown.Item key={index} onClick={() => setLatestItems(option.value)}>
                                    {option.name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </Col>
                </Row>

                <Row className='pt-4'>
                    <Col>
                        <h4>
                            商品の表示
                        </h4>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col>
                        <DropdownButton id="displayStyleDropdown" title={`${displayStyle}`}>
                            <Dropdown.Item onClick={() => setDisplayStyle('card')}>カード表示</Dropdown.Item>
                            <Dropdown.Item onClick={() => setDisplayStyle('grid')}>グリッド表示</Dropdown.Item>
                            <Dropdown.Item onClick={() => setDisplayStyle('list')}>リスト表示</Dropdown.Item>
                            <Dropdown.Item onClick={() => setDisplayStyle('mosaic')}>モザイクタイル表示</Dropdown.Item>
                            <Dropdown.Item onClick={() => setDisplayStyle('magazine')}>マガジンスタイル表示</Dropdown.Item>
                        </DropdownButton>
                    </Col>
                </Row>

                <Row className='pt-4'>
                    <Col>
                        <h4>
                            SNSの表示
                        </h4>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>表示するSNSの選択</Form.Label>
                        <DropdownButton id="dropdown-basic-button" title="SNSを選択">
                            {SnsOptions.map(option => (
                                <Dropdown.Item key={option.value} onClick={() => handleSnsSelection(option.value)}>
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </Col>
                </Row>
                {SnsOptions.filter(option => selectedSns.includes(option.value)).map(option => (
                    <Row key={option.value} className="mb-3">
                        <Col>
                            <div className='d-flex align-items-center'>
                                    <Form.Label>
                                        {option.label} URL: 
                                        <option.Icon className='mx-1' size={15} color={option.defaultColor} />
                                    </Form.Label>
                                    <Button className='mx-2' variant="outline-primary" size='sm' onClick={() => removeSnsSelection(option.value)}>
                                        削除
                                    </Button>
                            </div>         
                            <Form.Control
                                type="text"
                                placeholder={`${option.label}のURL`}
                                value={snsUrls[option.value].url}
                                onChange={(e) => handleSnsUrlChange(option.value, e.target.value)}
                                onBlur={() => handleSnsUrlBlur(option.value)} // 入力フィールドからフォーカスが外れたときにバリデーション
                                isInvalid={!!snsUrls[option.value].validationMessage} // バリデーションメッセージがある場合は無効状態に
                            />
                            <Form.Control.Feedback type="invalid">
                                {snsUrls[option.value].validationMessage}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                ))}
                <Row className="mb-3">
                    <Col>
                        <Form.Label>アイコンサイズ: {iconSize}px</Form.Label>
                        <Form.Control
                            type="range"
                            min="20" // 最小値
                            max="100"   // 最大値
                            step="0.1" // ステップサイズ
                            className="custom-range"
                            value={iconSize} // 現在のフォントサイズ
                            onChange={e => setIconSize(e.target.value)} // フォントサイズの更新
                        />
                    </Col>
                </Row>
                
                <Button className='mt-3' variant="outline-primary" onClick={handleEditToggle}>編集を終了</Button>
            </div>
        </div>
    );
};

export default EditPanel;
