import imageCompression from 'browser-image-compression';
import heic2any from 'heic2any';

/**
 * 非同期でブラウザがWebPをサポートしているかを確認する関数
 * @returns {Promise<boolean>} - WebPがサポートされている場合はtrue
 */
const isWebPSupported = () => {
    return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        if (!!(canvas.getContext && canvas.getContext('2d'))) {
            const data = canvas.toDataURL('image/webp');
            resolve(data.indexOf('data:image/webp') === 0);
        } else {
            resolve(false);
        }
    });
};


/**
 * 画像ファイルをWebP形式に変換する関数
 * @param {File} file - 変換する画像ファイル
 * @returns {Promise<File>} - WebP形式のFileオブジェクト
 */
export const convertToWebP = async (file) => {
    const webPSupported = await isWebPSupported();
    const targetFileType = webPSupported ? 'image/webp' : 'image/jpeg';
    console.log('WebP supported:', webPSupported);
    console.log('Target file type:', targetFileType);

    const options = {
        maxSizeMB: 0.5,                // 最大サイズ（MB）
        maxWidthOrHeight: 1920,        // 最大幅または高さ（ピクセル）
        useWebWorker: true,            // Web Workerの使用
        fileType: targetFileType,        // 出力ファイルタイプ
        initialQuality: 0.75,           // 初期品質（0〜1）
    };
    const heicMimeTypes = ['image/heic', 'image/heif', 'image/heic-sequence', 'image/heif-sequence'];
    const isHEIC = heicMimeTypes.includes(file.type) || /\.(heic|heif)$/i.test(file.name);
    
    console.log('Original file type:', file.type);
    console.log('Original file name:', file.name);
    console.log('Is HEIC:', isHEIC);
    
    try {
        let processedFile = file;

        // ファイルがHEIC形式の場合、JPEGに変換
        if (isHEIC) {
            const convertedBlob = await heic2any({
                blob: file,
                toType: 'image/jpeg',
                quality: 0.8, // 必要に応じて品質を調整
            });

            // 変換されたBlobをFileオブジェクトに変換
            processedFile = new File([convertedBlob], file.name.replace(/\.\w+$/, '.jpg'), {
                type: 'image/jpeg',
                lastModified: Date.now(),
            });
            console.log('Converted HEIC to JPEG:', processedFile);
        } else {
            console.log('File is not HEIC/HEIF, skipping HEIC conversion.');
        }
        // 圧縮処理を実行
        const compressedBlob = await imageCompression(processedFile, options);
        console.log('Compressed blob type:', compressedBlob.type);
        console.log('Compressed blob size (MB):', compressedBlob.size / (1024 * 1024));

        // 圧縮後のBlobタイプに基づいてファイル名と拡張子を設定
        let newExtension;
        if (compressedBlob.type === 'image/webp') {
            newExtension = '.webp';
        } else if (compressedBlob.type === 'image/jpeg') {
            newExtension = '.jpeg';
        } else {
            throw new Error('エラー: サポートされていないファイルタイプ');
        }
        const newName = processedFile.name.replace(/\.\w+$/, newExtension);

        // 新しい File オブジェクトを作成（Blobタイプに基づいて設定）
        const convertedFile = new File([compressedBlob], newName, {
            type: compressedBlob.type, // 実際のBlobタイプを設定
            lastModified: Date.now(),
        });
        console.log('Final file:', convertedFile);
        
        return { convertedFile, isWebP: convertedFile.type === 'image/webp' };
    } catch (error) {
        console.error('WebP変換エラー:', error);
        throw error;
    }
};

/**
 * Base64文字列をBlobに変換する関数
 * @param {string} base64 - Base64エンコードされたデータ
 * @param {string} mime - MIMEタイプ
 * @returns {Blob} - Blobオブジェクト
 */
export const base64ToBlob = (base64, mime) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
};