import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useUpdateShopProfileMutation } from '../slices/shopsApiSlice';
import TextareaAutosize from 'react-textarea-autosize';

const GoogleMapEmbed = ({ shopId, initialEmbedSrc, showEditButton }) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [embedHtml, setEmbedHtml] = useState('');
    const [embedSrc, setEmbedSrc] = useState(initialEmbedSrc || '');
    const [tempEmbedSrc, setTempEmbedSrc] = useState(initialEmbedSrc || '');
    const [error, setError] = useState('');

    const [updateShopProfile, { isLoading: isUpdateLoading, isSuccess, isError, error: updateError }] = useUpdateShopProfileMutation();

    useEffect(() => {
        if (isSuccess) {
            toast.success('Googleマップ埋め込みリンクが更新されました。');
            setEmbedSrc(tempEmbedSrc); // Update the displayed embedSrc after successful update
            setIsEditMode(false);
        } else if (isError) {
            toast.error('更新中にエラーが発生しました。');
        }
    }, [isSuccess, isError, updateError, tempEmbedSrc]);

    const handleEditToggle = async () => {
        if (isEditMode) {
            const src = extractSrcFromEmbedHtml(embedHtml);
            if (src && validateEmbedSrc(src)) {
                setTempEmbedSrc(src); // Temporarily store the new src
                await updateShopProfile({
                    id: shopId,
                    googleMapsEmbedSrc: src,
                }).unwrap();
            } else {
                toast.error('有効なGoogleマップ埋め込みリンクを入力してください。');
            }
        } else {
            setIsEditMode(true);
        }
    };

    const handleCancel = () => {
        setIsEditMode(false);
        setEmbedHtml(''); // Clear the input field
        setError('');
    };

    const extractSrcFromEmbedHtml = (html) => {
        const match = html.match(/src="([^"]+)"/);
        return match ? match[1] : null;
    };

    const validateEmbedSrc = (src) => {
        const embedSrcPattern = /^https:\/\/www\.google\.com\/maps\/embed?.*$/;
        return embedSrcPattern.test(src.trim());
    };

    return (
        <div>
            {isEditMode ? (
                <>
                    <Form.Group controlId='googleMapsEmbedHtml' className='my-2'>
                        <Form.Label><strong>Googleマップ埋め込みリンク</strong></Form.Label>
                        <TextareaAutosize
                            minRows={3}
                            className="form-control"
                            placeholder='埋め込みリンクを入力してください'
                            value={embedHtml}
                            onChange={(e) => setEmbedHtml(e.target.value)}
                            isInvalid={!!error}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                        <Col md={2}>
                            <Button variant="primary" onClick={handleEditToggle} disabled={isUpdateLoading}>
                                保存
                            </Button>
                        </Col>
                        <Col>
                            <Button variant="secondary" onClick={handleCancel}>
                                キャンセル
                            </Button>
                        </Col>
                    </Row>
                    <Alert variant="info" className="mt-3">
                        Googleマップの埋め込みリンクを取得するには:（30秒ほど）
                        <ol className='mt-1'>
                            <li>1. Googleマップでお店の場所を検索します。</li>
                            <li>2. 「概要」をクリックします。</li>
                            <li>3. 「共有」ボタンをクリックします。</li>
                            <li>4. 「地図を埋め込む」を選択します。</li>
                            <li>5. 「HTMLをコピー」ボタンを押すと、表示されているiframeコードがコピーされます。</li>
                            <li>6. コピーしたものを、ここに貼り付けます。</li>
                        </ol>
                    </Alert>
                </>
            ) : (
                <>
                    {embedSrc ? (
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe src={embedSrc} width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    ) : showEditButton ? (<>
                        <Alert variant="info">
                            Googleマップを埋め込んでみましょう。
                        </Alert>
                        <Button variant="primary" onClick={() => setIsEditMode(true)} className='mt-3'>
                            編集
                        </Button>
                    </>) : null}
                </>
            )}
        </div>
    );
};

export default GoogleMapEmbed;
