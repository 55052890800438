import { useState, useEffect } from 'react';
import { Button, Modal, Form, Badge, Row, Col, ListGroup, Alert } from 'react-bootstrap';
import { useGetTagsQuery } from '../slices/tagsApiSlice';
import { useAddTagsToProductMutation, useRemoveTagsFromProductMutation } from '../slices/productsApiSlice';
import { toast } from 'react-toastify';
import Loader from './Loader';
import { MAX_TAGS } from '../constants/constants';

const TagManagement = ({ productId, existingTags, refetch, isEditing }) => {
    const { data: tags, isLoading: tagsLoading } = useGetTagsQuery();
    const [addTagsToProduct, { isLoading: loadingAddTags }] = useAddTagsToProductMutation();
    const [removeTagsFromProduct, { isLoading: loadingRemoveTags }] = useRemoveTagsFromProductMutation();
    const [selectedTagsToAdd, setSelectedTagsToAdd] = useState([]);
    const [selectedTagsToRemove, setSelectedTagsToRemove] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const handleAddTagClick = (tagId) => {
        if (selectedTagsToAdd.includes(tagId)) {
            setSelectedTagsToAdd(selectedTagsToAdd.filter(id => id !== tagId));
        } else {
            setSelectedTagsToAdd([...selectedTagsToAdd, tagId]);
        }
    };

    const handleRemoveTagClick = (tagId) => {
        if (selectedTagsToRemove.includes(tagId)) {
            setSelectedTagsToRemove(selectedTagsToRemove.filter(id => id !== tagId));
        } else {
            setSelectedTagsToRemove([...selectedTagsToRemove, tagId]);
        }
    };

    const submitAddTagsHandler = async (e) => {
        e.preventDefault();
        const totalAfterAdd = existingTags.length - selectedTagsToRemove.length + selectedTagsToAdd.length;
        if (totalAfterAdd > MAX_TAGS) {
            toast.error(`タグは最大${MAX_TAGS}つまでしか追加できません。`);
            return;
        }
        try {
            await addTagsToProduct({ productId, tagIds: selectedTagsToAdd }).unwrap();
            setSelectedTagsToAdd([]);
            toast.success('タグが追加されました');
            refetch();
        } catch (err) {
            toast.error(err?.data?.message || 'タグの追加に失敗しました');
        }
    };

    const submitRemoveTagsHandler = async (e) => {
        e.preventDefault();
        try {
            await removeTagsFromProduct({ productId, tagIds: selectedTagsToRemove }).unwrap();
            setSelectedTagsToRemove([]);
            toast.success('タグが削除されました');
            refetch();
        } catch (err) {
            toast.error('タグの削除に失敗しました');
        }
    };

    const existingTagIds = existingTags.map(tag => tag.id);

    if (isEditing) {
        return (
            <>
                <Row>
                    <Col>
                        <Button variant="outline-primary" onClick={() => setShowModal(true)}>
                            <small>タグを編集</small>
                        </Button>
                    </Col>
                    <Col className='text-end'>
                        {existingTags.map(tag => (
                            <Badge key={tag.Id} bg='primary' className="me-1">
                                {tag.name}
                            </Badge>
                        ))}
                    </Col>
                </Row>

                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>タグの管理</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ListGroup>
                        <ListGroup.Item>
                            <h4>商品のタグ</h4>
                            {tagsLoading ? (
                                <div>Loading tags...</div>
                            ) : (
                                existingTags.map(existingTag => (
                                    tags.find(tag => tag.id === existingTag.id) && (
                                        // <Button
                                        //     key={existingTag.id}
                                        //     variant={selectedTagsToRemove.includes(existingTag.id) ? "danger" : "info"}
                                        //     className="m-1"
                                        //     onClick={() => handleRemoveTagClick(existingTag.id)}
                                        // >
                                        //     {tags.find(tag => tag.id === existingTag.id).name}
                                        // </Button>
                                        <Alert
                                            key={existingTag.id}
                                            variant={selectedTagsToRemove.includes(existingTag.id) ? "danger" : "light"}
                                            className="m-1"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleRemoveTagClick(existingTag.id)}
                                        >
                                            {existingTag.name}
                                        </Alert>
                                    )
                                ))
                            )} <br />
                            <Button className="" variant="outline-danger" onClick={submitRemoveTagsHandler} disabled={loadingRemoveTags || selectedTagsToRemove.length === 0}>
                                <small>タグを削除</small>
                            </Button>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col><h4>タグを追加</h4></Col>
                                <Col className='text-end'>
                                    <small>現在のタグ数: {existingTags.length - selectedTagsToRemove.length + selectedTagsToAdd.length}/{MAX_TAGS}</small>
                                </Col>
                            </Row>
                            {tagsLoading ? (
                                <div>Loading tags...</div>
                            ) : (
                                tags.filter(tag => !existingTagIds.includes(tag.id)).map(tag => (
                                    <Button
                                        key={tag.id}
                                        variant={selectedTagsToAdd.includes(tag.id) ? "success" : "light"}
                                        className="m-1"
                                        onClick={() => handleAddTagClick(tag.id)}
                                    >
                                        <small>{tag.name}</small>
                                    </Button>
                                ))
                            )} <br />
                            <Button className="mt-3" variant="outline-success" onClick={submitAddTagsHandler} disabled={loadingAddTags || selectedTagsToAdd.length === 0}>
                                <small>タグを追加</small>
                            </Button>
                        </ListGroup.Item>
                        </ListGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
                            終了
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    } else {
        if (tagsLoading) {
            <Loader />;
        } else {
            return (
                <Row>
                    <Col>
                        タグ:
                    </Col>
                    <Col className='text-end'>
                        {existingTags.map(tag => (
                            <Badge key={tag.Id} bg="primary" className="me-1">
                                {tag.name}
                            </Badge>
                        ))}
                    </Col>
                </Row>
            );
        }
    }
};

export default TagManagement;