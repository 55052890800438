import { apiSlice } from './apiSlice';
import { BRANDS_URL } from '../constants/constants';

export const brandsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getBrands: builder.query({
            query: () => BRANDS_URL,
            providesTags: ['Brand'],
        }),
    }),
});

export const { 
    useGetBrandsQuery
} = brandsApiSlice;
