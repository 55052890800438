import { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import {
    useGetOrganizationsQuery,
    useGetOrganizationByIdQuery,
    useUpdateOrganizationByAdminMutation,
    useDeleteOrganizationMutation,
    useRegisterOrganizationByAdminMutation,
} from '../../slices/organizationsApiSlice';

const AdminOrganizationScreen = () => {
    const { data: organizations, isLoading, error, refetch } = useGetOrganizationsQuery();
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);

    const {
        data: selectedOrganization,
        isLoading: loadingOrganizationDetails,
        error: errorOrganizationDetails,
        refetch: refetchOrganizationDetails,
    } = useGetOrganizationByIdQuery(selectedOrganizationId, {
        skip: !selectedOrganizationId,
    });

    const [updateOrganization, { isLoading: loadingUpdate }] = useUpdateOrganizationByAdminMutation();
    const [deleteOrganization, { isLoading: loadingDelete }] = useDeleteOrganizationMutation();
    const [registerOrganization, { isLoading: loadingRegister }] = useRegisterOrganizationByAdminMutation();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        address: {
            street: '',
            city: '',
            state: '',
            zipCode: '',
        },
        phoneNumber: '',
        representative: '',
        memberLimit: '',
    });

    const [newOrganizationData, setNewOrganizationData] = useState({
        name: '',
        email: '',
        address: {
            street: '',
            city: '',
            state: '',
            zipCode: '',
        },
        phoneNumber: '',
        representative: '',
        password: '',
        confirmPassword: '',
        memberLimit: '', 
    });

    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);

    const handleEditModalClose = () => setShowEditModal(false);
    const handleEditModalShow = (id) => {
        setSelectedOrganizationId(id);
        setShowEditModal(true);
    };

    const handleAddModalClose = () => setShowAddModal(false);
    const handleAddModalShow = () => setShowAddModal(true);

    useEffect(() => {
        if (selectedOrganization) {
            setFormData({
                name: selectedOrganization.name,
                email: selectedOrganization.email,
                address: {
                    street: selectedOrganization.address.street,
                    city: selectedOrganization.address.city,
                    state: selectedOrganization.address.state,
                    zipCode: selectedOrganization.address.zipCode,
                },
                phoneNumber: selectedOrganization.phoneNumber,
                representative: selectedOrganization.representative,
                memberLimit: selectedOrganization.memberLimit,  // 追加
            });
        }
    }, [selectedOrganization]);

    const handleChange = (e, setData) => {
        const { name, value } = e.target;
        if (name.includes('.')) {
            const [group, key] = name.split('.');
            setData((prev) => ({
                ...prev,
                [group]: {
                    ...prev[group],
                    [key]: value,
                },
            }));
        } else {
            setData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const updateHandler = async (e) => {
        e.preventDefault();
        try {
            await updateOrganization({ id: selectedOrganizationId, ...formData }).unwrap();
            toast.success('組織が更新されました');
            refetch();
            refetchOrganizationDetails();
            handleEditModalClose();
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    const deleteHandler = async (id) => {
        if (window.confirm('この組織を削除してもよろしいですか？')) {
            try {
                await deleteOrganization(id).unwrap();
                toast.success('組織が削除されました');
                refetch();
                setSelectedOrganizationId(null);
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    const registerHandler = async (e) => {
        e.preventDefault();
        if (newOrganizationData.password !== newOrganizationData.confirmPassword) {
            toast.error('パスワードが一致しません');
            return;
        }
        try {
            await registerOrganization(newOrganizationData).unwrap();
            toast.success('新しい組織が追加されました');
            setNewOrganizationData({
                name: '',
                email: '',
                address: {
                    street: '',
                    city: '',
                    state: '',
                    zipCode: '',
                },
                phoneNumber: '',
                representative: '',
                password: '',
                confirmPassword: '',  // 追加
                memberLimit: '',
            });
            refetch();
            handleAddModalClose();
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };    

    return (
        <Row>
            <Col md={6}>
                <h2>団体管理</h2>
                {isLoading ? (
                    <Loader />
                ) : error ? (
                    <Message variant='danger'>{error?.data?.message || error.error}</Message>
                ) : (
                    <ListGroup variant='flush'>
                        {organizations.map((organization) => (
                            <ListGroup.Item key={organization.id}>
                                <Row>
                                    <Col>{organization.name}</Col>
                                    <Col>{organization.email}</Col>
                                    <Col>
                                        <Button
                                            variant='light'
                                            className='btn-sm'
                                            onClick={() => handleEditModalShow(organization.id)}
                                        >
                                            詳細
                                        </Button>
                                        <Button
                                            variant='danger'
                                            className='btn-sm'
                                            onClick={() => deleteHandler(organization.id)}
                                        >
                                            削除
                                        </Button>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                )}
                <Button variant='primary' onClick={handleAddModalShow} className='my-3'>
                    新しい団体を追加
                </Button>
            </Col>
            <Col md={6}>
                {/* 編集用モーダル */}
                <Modal show={showEditModal} onHide={handleEditModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>団体編集</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loadingOrganizationDetails ? (
                            <Loader />
                        ) : errorOrganizationDetails ? (
                            <Message variant='danger'>
                                {errorOrganizationDetails?.data?.message || errorOrganizationDetails.error}
                            </Message>
                        ) : (
                            <Form onSubmit={updateHandler}>
                                <Form.Group controlId='name' className='my-2'>
                                    <Form.Label>名前</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='名前を入力'
                                        name='name'
                                        value={formData.name}
                                        onChange={(e) => handleChange(e, setFormData)}
                                    />
                                </Form.Group>
                                <Form.Group controlId='email' className='my-2'>
                                    <Form.Label>メールアドレス</Form.Label>
                                    <Form.Control
                                        type='email'
                                        placeholder='メールアドレスを入力'
                                        name='email'
                                        value={formData.email}
                                        onChange={(e) => handleChange(e, setFormData)}
                                    />
                                </Form.Group>
                                <Form.Group controlId='address.zipCode' className='my-2'>
                                    <Form.Label>郵便番号</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='郵便番号を入力'
                                        name='address.zipCode'
                                        value={formData.address.zipCode}
                                        onChange={(e) => handleChange(e, setFormData)}
                                    />
                                </Form.Group>
                                <Form.Group controlId='address.state' className='my-2'>
                                    <Form.Label>住所（都道府県）</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='都道府県を入力'
                                        name='address.state'
                                        value={formData.address.state}
                                        onChange={(e) => handleChange(e, setFormData)}
                                    />
                                </Form.Group>
                                <Form.Group controlId='address.city' className='my-2'>
                                    <Form.Label>住所（市区町村）</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='市区町村を入力'
                                        name='address.city'
                                        value={formData.address.city}
                                        onChange={(e) => handleChange(e, setFormData)}
                                    />
                                </Form.Group>
                                <Form.Group controlId='address.street' className='my-2'>
                                    <Form.Label>住所（番地/部屋番号）</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='番地/部屋番号を入力'
                                        name='address.street'
                                        value={formData.address.street}
                                        onChange={(e) => handleChange(e, setFormData)}
                                    />
                                </Form.Group>
                                <Form.Group controlId='phoneNumber' className='my-2'>
                                    <Form.Label>電話番号</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='電話番号を入力'
                                        name='phoneNumber'
                                        value={formData.phoneNumber}
                                        onChange={(e) => handleChange(e, setFormData)}
                                    />
                                </Form.Group>
                                <Form.Group controlId='representative' className='my-2'>
                                    <Form.Label>代表者</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='代表者を入力'
                                        name='representative'
                                        value={formData.representative}
                                        onChange={(e) => handleChange(e, setFormData)}
                                    />
                                </Form.Group>
                                <Form.Group controlId='memberLimit' className='my-2'>
                                    <Form.Label>メンバーの上限数</Form.Label>
                                    <Form.Control
                                        type='number'
                                        placeholder='メンバーの上限数を入力'
                                        name='memberLimit'
                                        value={formData.memberLimit}
                                        onChange={(e) => handleChange(e, setFormData)}
                                    />
                                </Form.Group>
                                <Button type='submit' variant='primary' className='my-2'>
                                    更新
                                </Button>
                                {loadingUpdate && <Loader />}
                            </Form>
                        )}
                    </Modal.Body>
                </Modal>
                {/* 追加用モーダル */}
                <Modal show={showAddModal} onHide={handleAddModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>新しい団体を追加</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={registerHandler}>
                            <Form.Group controlId='name' className='my-2'>
                                <Form.Label>名前</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='名前を入力'
                                    name='name'
                                    value={newOrganizationData.name}
                                    onChange={(e) => handleChange(e, setNewOrganizationData)}
                                />
                            </Form.Group>
                            <Form.Group controlId='email' className='my-2'>
                                <Form.Label>メールアドレス</Form.Label>
                                <Form.Control
                                    type='email'
                                    placeholder='メールアドレスを入力'
                                    name='email'
                                    value={newOrganizationData.email}
                                    onChange={(e) => handleChange(e, setNewOrganizationData)}
                                />
                            </Form.Group>
                            <Form.Group controlId='address.zipCode' className='my-2'>
                                <Form.Label>郵便番号</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='郵便番号を入力'
                                    name='address.zipCode'
                                    value={newOrganizationData.address.zipCode}
                                    onChange={(e) => handleChange(e, setNewOrganizationData)}
                                />
                            </Form.Group>
                            <Form.Group controlId='address.state' className='my-2'>
                                <Form.Label>住所（都道府県）</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='都道府県を入力'
                                    name='address.state'
                                    value={newOrganizationData.address.state}
                                    onChange={(e) => handleChange(e, setNewOrganizationData)}
                                />
                            </Form.Group>
                            <Form.Group controlId='address.city' className='my-2'>
                                <Form.Label>住所（市区町村）</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='市区町村を入力'
                                    name='address.city'
                                    value={newOrganizationData.address.city}
                                    onChange={(e) => handleChange(e, setNewOrganizationData)}
                                />
                            </Form.Group>
                            <Form.Group controlId='address.street' className='my-2'>
                                <Form.Label>住所（番地/部屋番号）</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='番地/部屋番号を入力'
                                    name='address.street'
                                    value={newOrganizationData.address.street}
                                    onChange={(e) => handleChange(e, setNewOrganizationData)}
                                />
                            </Form.Group>
                            <Form.Group controlId='phoneNumber' className='my-2'>
                                <Form.Label>電話番号</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='電話番号を入力'
                                    name='phoneNumber'
                                    value={newOrganizationData.phoneNumber}
                                    onChange={(e) => handleChange(e, setNewOrganizationData)}
                                />
                            </Form.Group>
                            <Form.Group controlId='representative' className='my-2'>
                                <Form.Label>代表者</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='代表者を入力'
                                    name='representative'
                                    value={newOrganizationData.representative}
                                    onChange={(e) => handleChange(e, setNewOrganizationData)}
                                />
                            </Form.Group>
                            <Form.Group controlId='password' className='my-2'>
                                <Form.Label>パスワード</Form.Label>
                                <Form.Control
                                    type='password'
                                    placeholder='パスワードを入力'
                                    name='password'
                                    value={newOrganizationData.password}
                                    onChange={(e) => handleChange(e, setNewOrganizationData)}
                                />
                            </Form.Group>
                            <Form.Group controlId='confirmPassword' className='my-2'>
                                <Form.Label>パスワード確認</Form.Label>
                                <Form.Control
                                    type='password'
                                    placeholder='パスワード確認を入力'
                                    name='confirmPassword'
                                    value={newOrganizationData.confirmPassword}
                                    onChange={(e) => handleChange(e, setNewOrganizationData)}
                                />
                            </Form.Group>

                            <Form.Group controlId='memberLimit' className='my-2'>
                                <Form.Label>メンバーの上限数</Form.Label>
                                <Form.Control
                                    type='number'
                                    placeholder='メンバーの上限数を入力'
                                    name='memberLimit'
                                    value={newOrganizationData.memberLimit}
                                    onChange={(e) => handleChange(e, setNewOrganizationData)}
                                />
                            </Form.Group>
                            <Button type='submit' variant='primary' className='my-2'>
                                追加
                            </Button>
                            {loadingRegister && <Loader />}
                        </Form>
                    </Modal.Body>
                </Modal>
            </Col>
        </Row>
    );
};

export default AdminOrganizationScreen;
