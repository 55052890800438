// src/screens/ShopOwnerEditScreen.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useGetShopOwnerByIdQuery, useUpdateShopOwnerMutation } from '../../slices/shopOwnersApiSlice';
import { Container, Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ShopOwnerEditScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: owner, isLoading, isError, error, refetch } = useGetShopOwnerByIdQuery(id);
  const [updateShopOwner, { isLoading: isUpdating, isError: isUpdateError, error: updateError, isSuccess }] = useUpdateShopOwnerMutation();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phoneNumber: '',
    serialPrinter: '',
    bluetoothPrinter: '',
    postalCode: '',
    prefecture: '',
    city: '',
    streetAddress: '',
    buildingName: '',
  });

  useEffect(() => {
    if (owner) {
      setFormData({
        name: owner.name || '',
        email: owner.email || '',
        password: '', // パスワードは空にしておく
        phoneNumber: owner.phoneNumber || '',
        serialPrinter: owner.serialPrinter || '',
        bluetoothPrinter: owner.bluetoothPrinter || '',
        postalCode: owner.postalCode || '',
        prefecture: owner.prefecture || '',
        city: owner.city || '',
        streetAddress: owner.streetAddress || '',
        buildingName: owner.buildingName || '',
      });
    }
  }, [owner]);

  useEffect(() => {
    if (isSuccess) {
      toast.success('更新しました。');
      refetch(); // 更新後のリダイレクト先
    }
  }, [isSuccess, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // 構造化データの作成
    const updatedData = {
      ...formData,
      address: {
        postalCode: formData.postalCode,
        prefecture: formData.prefecture,
        city: formData.city,
        streetAddress: formData.streetAddress,
        buildingName: formData.buildingName,
      },
    };
    // パスワードが空の場合は含めない
    if (!updatedData.password) {
      delete updatedData.password;
    }
    delete updatedData.postalCode;
    delete updatedData.prefecture;
    delete updatedData.city;
    delete updatedData.streetAddress;
    delete updatedData.buildingName;

    updateShopOwner({ id, ...updatedData });
  };

  if (isLoading) return <Spinner animation="border" className="m-5" />;
  if (isError) return <Alert variant="danger" className="m-5">エラーが発生しました: {error.data?.message || error.error}</Alert>;

  return (
    <Container>
      <Row className="mb-4 justify-content-center">
        <Col md={6}>
          <h1>ショップオーナー情報編集</h1>
          <Button as={Link} to="/" variant="secondary" className="mt-3">
            戻る
          </Button>
        </Col>
      </Row>
      <Row  className="justify-content-center">
        <Col md={6}>
          {isUpdateError && <Alert variant="danger">更新エラー: {updateError.data?.message || updateError.error}</Alert>}
          <Form onSubmit={handleSubmit}>
            {/* 名前 */}
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>名前</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {/* メールアドレス */}
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {/* パスワード */}
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>パスワード（変更する場合のみ入力）</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </Form.Group>

            {/* 電話番号 */}
            <Form.Group className="mb-3" controlId="phoneNumber">
              <Form.Label>電話番号</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </Form.Group>

            {/* シリアルプリンター */}
            <Form.Group className="mb-3" controlId="serialPrinter">
              <Form.Label>シリアルプリンター</Form.Label>
              <Form.Control
                type="text"
                name="serialPrinter"
                value={formData.serialPrinter}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="bluetoothPrinter">
              <Form.Label>Bluetoothプリンター</Form.Label>
              <Form.Control
                type="text"
                name="bluetoothPrinter"
                value={formData.bluetoothPrinter}
                onChange={handleChange}
              />
            </Form.Group>

            <h5>住所</h5>
            <Form.Group className="mb-3" controlId="postalCode">
              <Form.Label>郵便番号</Form.Label>
              <Form.Control
                type="text"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="prefecture">
              <Form.Label>都道府県</Form.Label>
              <Form.Control
                type="text"
                name="prefecture"
                value={formData.prefecture}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="city">
              <Form.Label>市区町村</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="streetAddress">
              <Form.Label>番地</Form.Label>
              <Form.Control
                type="text"
                name="streetAddress"
                value={formData.streetAddress}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="buildingName">
              <Form.Label>建物名</Form.Label>
              <Form.Control
                type="text"
                name="buildingName"
                value={formData.buildingName}
                onChange={handleChange}
              />
            </Form.Group>

            <Button type="submit" variant="primary" disabled={isUpdating}>
              {isUpdating ? 'Loading...' : '更新'}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ShopOwnerEditScreen;