// hooks/useCategoryMeasurements.js
import { useGetCategoryMapQuery } from "../slices/categoriesApiSlice";

const useCategoryMeasurements = (categoryId) => {
    const { data: categoryMap, isLoading, error } = useGetCategoryMapQuery();

    const getMeasurements = () => {
        if (!categoryMap || !categoryId) return [];

        let category = categoryMap[categoryId];
        while (category) {
            if (category.measurements && category.measurements.length > 0) {
                return category.measurements;
            }
            if (category.parentId) {
                category = categoryMap[category.parentId];
            } else {
                category = null;
            }
        }
        return [];
    };

    return { measurements: getMeasurements(), isLoading, error };
};

export default useCategoryMeasurements;