import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetShopBySlugQuery, useUpdateShopProfileMutation, useUploadShopCoverMutation } from '../slices/shopsApiSlice';
import { useGetProductsQuery } from '../slices/productsApiSlice';
import { useParams, useLocation } from 'react-router-dom';
import { Row, Col, Spinner, Alert, Button, Form, Container } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import EditPanel from '../components/EditPanel';
import ShopHomeTop from '../components/ShopHomeTop';
import ShopNavigationBar from '../components/ShopNavigationBar';
import ShopLatestItems from '../components/ShopLatestItems';
import ShopProductsDisplay from '../components/ShopProductsDisplay';
import ShopSnsLinks from '../components/ShopSnsLinks';
import GoogleMapsEmbed from '../components/GoogleMapsEmbed';
import Loader from '../components/Loader';
import { convertToWebP } from '../utils/uploadUtils';

const ShopHomeScreen = () => {
    const { userInfo } = useSelector((state) => state.auth);

    const slug = useParams().slug;
    const location = useLocation();

    const [content, setContent] = useState('home');
    useEffect(() => {
        if (location.pathname.includes('about')) {
            setContent('about');
        } else if (location.pathname.includes('contact')) {
            setContent('contact');
        } else {
            setContent('home');
        }
    }, [location]);

    const { data: shop, isError, isLoading } = useGetShopBySlugQuery(slug);
    const shopId = shop?.id;
    const {
        data: productsData,
        isError: productsError,
        isLoading: productsLoading
    } = useGetProductsQuery({
        shopId,
    });
    const [updateShopProfile, { isLoading: isUpdateLoading, isSuccess, isError: isUpdateError, error }] = useUpdateShopProfileMutation();
    const [uploadShopCover, { isLoading: loadingUpload }] = useUploadShopCoverMutation();

    const [isEditMode, setIsEditMode] = useState(false);

    // 背景色の状態を追加
    const [backgroundColor, setBackgroundColor] = useState('#ffffff'); // デフォルトの背景色

    const [coverImage, setCoverImage] = useState(''); // ショップカバー画像

    const [homeTop, setHomeTop] = useState(shop?.homeTop || 1); // デフォルトのホームトップレイアウト
    
    const [logoFontSize, setLogoFontSize] = useState('2rem'); // デフォルトのフォントサイズ
    const [logoFont, setLogoFont] = useState('Arial'); // デフォルトのフォント
    const [logoFontWeight, setLogoFontWeight] = useState('bold'); // デフォルトのフォントの太さ
    const [logoFontColor, setLogoFontColor] = useState('#000000');

    const shadowOptions = [
        { name: 'なし', value: '0px 0px 0px' },
        { name: '軽い', value: '1px 1px 2px' },
        { name: '普通', value: '2px 2px 4px' },
        { name: '強い', value: '4px 4px 8px' },
    ];

    const [shadowOption, setShadowOption] = useState(shadowOptions[0].value); // デフォルトは「なし」
    const [shadowColor, setShadowColor] = useState('#000000'); 

    const [nav, setNav] =useState(shop?.nav || 1);
    const [navFontSize, setNavFontSize] = useState('1.7rem');
    const [navFontColor, setNavFontColor] = useState('#000000');
    const [navFontFamily, setNavFontFamily] = useState('Arial');
    const [navFontWeight, setNavFontWeight] = useState('bold');

    const [latestItems, setLatestItems] = useState('gallery');

    const [displayStyle, setDisplayStyle] = useState('card');

    const [selectedSns, setSelectedSns] = useState([]);
    const [iconSize, setIconSize] = useState(40);
    const [snsUrls, setSnsUrls] = useState({
        instagram: { url: '', validationMessage: '' },
        facebook: { url: '', validationMessage: '' },
        twitter: { url: '', validationMessage: '' },
        line: { url: '', validationMessage: '' },
    });

    useEffect(() => {
        if (isSuccess) {
            toast.success('ショップ情報が更新されました。');
        } else if (isUpdateError) {
            toast.error('更新中にエラーが発生しました。');
        }
    }, [isSuccess, isError, error]);

    useEffect(() => {
        if (shop) {
            setCoverImage(shop.coverImage || '');
            setBackgroundColor(shop.customizations?.backgroundColor || '#ffffff');
            setHomeTop(shop.customizations?.homeTopLayout || 1);
            setLogoFontSize(shop.customizations?.logo?.fontSize || '2rem');
            setLogoFont(shop.customizations?.logo?.fontFamily || 'Arial');
            setLogoFontWeight(shop.customizations?.logo?.fontWeight || 'bold');
            setLogoFontColor(shop.customizations?.logo?.color || '#000000');
            setShadowOption(shop.customizations?.logo?.shadow?.option || '0px 0px 0px');
            setShadowColor(shop.customizations?.logo?.shadow?.color || '#000000');
            setNav(shop.customizations?.nav?.layout || 1);
            setNavFontSize(shop.customizations?.nav?.fontSize || '1rem');
            setNavFontColor(shop.customizations?.nav?.fontColor || '#000000');
            setNavFontFamily(shop.customizations?.nav?.fontFamily || 'Arial');
            setNavFontWeight(shop.customizations?.nav?.fontWeight || 'normal');
            setLatestItems(shop.customizations?.latestItemsLayout || 'gallery');
            setDisplayStyle(shop.customizations?.displayStyle || 'card');
            setSelectedSns(Object.keys(shop.customizations?.snsLinks || {}));
            setIconSize(shop.customizations?.snsIconSize || 40);
            setSnsUrls({
                instagram: { url: shop.customizations?.snsLinks?.instagram || '', validationMessage: '' },
                facebook: { url: shop.customizations?.snsLinks?.facebook || '', validationMessage: '' },
                twitter: { url: shop.customizations?.snsLinks?.twitter || '', validationMessage: '' },
                line: { url: shop.customizations?.snsLinks?.line || '', validationMessage: '' },
            });
        }
    }, [shop]);

    // SNSのデフォルトカラー
    const iconColors = {
        instagram: '#E1306C',
        facebook: '#3b5998',
        twitter: '#1DA1F2',
        line: '#00b900'
    };

    // 背景色を適用するスタイル
    const backgroundStyle = {
        backgroundColor: backgroundColor,
    };

    const logoStyle = {
        fontSize: logoFontSize, // フォントサイズ
        fontFamily: logoFont,   // フォントファミリー
        fontWeight: logoFontWeight, // フォントの太さ
        color: logoFontColor,
        textShadow: `${shadowOption} ${shadowColor}`,
    };

    const latestProducts = useMemo(() => {
        // データのロードが完了しているかどうかをここでチェック
        if (productsData && productsData.products && productsData.products.length > 0) {
          // 配列のコピーを作成し、そのコピーをソートする
          return [...productsData.products]
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(0, 10);
        }
        return [];
    }, [productsData]);

    const showEditButton = useMemo(() => {
        if (!shop || !userInfo) return false;
        return (userInfo.shopId === shop.id && userInfo.role === 'shop') || userInfo.role === 'admin';
    }, [userInfo, shop]);

    // 全てのSNS URLについて、バリデーションメッセージが空でないかチェックする関数
    const isAnyValidationMessage = () => {
        return Object.values(snsUrls).some(sns => sns.validationMessage !== '');
    };

    const handleEditToggle = async () => {
        if (isAnyValidationMessage()) {
            toast.error('SNSリンクの入力内容を確認してください。');
            return;
        }
        setIsEditMode(prev => !prev);
        if (isEditMode) {
            // サーバーにカスタマイズを保存するロジック
            await updateShopProfile({
                id: shopId,
                customizations: {
                    backgroundColor,
                    homeTopLayout: Number(homeTop),
                    logo: {
                        fontSize: logoFontSize,
                        fontFamily: logoFont,
                        fontWeight: logoFontWeight,
                        color: logoFontColor,
                        shadow: {
                            option: shadowOption,
                            color: shadowColor,
                        },
                    },
                    nav: {
                        layout: Number(nav),
                        fontSize: navFontSize,
                        fontColor: navFontColor,
                        fontFamily: navFontFamily,
                        fontWeight: navFontWeight,
                    },
                    latestItemsLayout: latestItems,
                    displayStyle,
                    snsLinks: {
                        instagram: snsUrls.instagram.url,
                        facebook: snsUrls.facebook.url,
                        twitter: snsUrls.twitter.url,
                        line: snsUrls.line.url,
                    },
                    snsIconSize: Number(iconSize),
                },
            });
        }
    };

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        try {
            toast.info('画像をアップロード中...');
            const webpFile = await convertToWebP(file);

            const formData = new FormData();
            formData.append('image', webpFile);
            formData.append('previousImage', coverImage);
            const res = await uploadShopCover(formData).unwrap();
            toast.success(res.message);
            setCoverImage(res.coverImage);
            // await updateProduct({
            //     productId,
            //     ...product,
            //     image: res.image,
            // }).unwrap();
            // refetch();
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    if (isLoading || productsLoading) return <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
    </Spinner>;

    if (isError || productsError) return <Alert variant="danger">
        Error loading shop or products.
    </Alert>;

    return (
        <div fluid style={backgroundStyle}>
            <Container>
                {showEditButton && content === 'home' && (
                    <Button className='my-3 shop-edit-btn ' variant="primary" onClick={handleEditToggle} disabled={isUpdateLoading}>
                        <FaEdit className="me-2 mb-1" />
                        {isEditMode ? "編集を終了" : "編集"}
                    </Button>
                )}

                
                <EditPanel
                    isEditMode={isEditMode}
                    handleEditToggle={handleEditToggle}
                    setBackgroundColor={setBackgroundColor}
                    homeTop={homeTop}
                    setHomeTop={setHomeTop}
                    logoFontSize={logoFontSize}
                    setLogoFontSize={setLogoFontSize}
                    setLogoFont={setLogoFont}
                    setLogoFontWeight={setLogoFontWeight}
                    setLogoFontColor={setLogoFontColor}
                    setShadowOption={setShadowOption}
                    setShadowColor={setShadowColor}
                    nav={nav}
                    setNav={setNav}
                    navFontSize={navFontSize}
                    setNavFontSize={setNavFontSize}
                    setNavFontColor={setNavFontColor}
                    setNavFontFamily={setNavFontFamily}
                    setNavFontWeight={setNavFontWeight}
                    latestItems={latestItems}
                    setLatestItems={setLatestItems}
                    displayStyle={displayStyle}
                    setDisplayStyle={setDisplayStyle}
                    snsUrls={snsUrls}
                    selectedSns={selectedSns}
                    setSelectedSns={setSelectedSns}
                    iconSize={iconSize}
                    setIconSize={setIconSize}
                    setSnsUrls={setSnsUrls}
                />
                
                <Row className='my-4'>
                    <Col>
                        <ShopHomeTop shop={shop} homeTop={homeTop} logoStyle={logoStyle} coverImage={coverImage} />
                        {isEditMode && (
                            <Form.Group controlId='image' className='my-2 mx-5'>
                                <Form.Label>画像</Form.Label>
                                <Form.Control 
                                    type='text' 
                                    placeholder='画像のurl' 
                                    value={coverImage} 
                                ></Form.Control>
                                <Form.Control
                                    type='file'
                                    label='写真を選択する'
                                    onChange={ uploadFileHandler }
                                ></Form.Control>
                            </Form.Group>
                        )}
                        {loadingUpload && <Loader />}
                    </Col>
                </Row>

                <Row className='py-3'>
                    <Col>
                        <ShopNavigationBar
                            nav={nav}
                            navFontSize={navFontSize}
                            navFontColor={navFontColor}
                            navFontFamily={navFontFamily}
                            navFontWeight={navFontWeight}
                            slug={slug}
                        />
                    </Col>
                </Row>

                {content === 'home' ? (
                    <>
                        {latestItems !== 'none' && (
                            <Row className='mb-3'>
                                <Col>
                                    <h2 className="mb-4">最新商品</h2>
                                    <ShopLatestItems latestItems={latestItems} latestProducts={latestProducts} />
                                </Col>
                            </Row>
                        )}
                    
                        <Row className="mb-3">
                            <Col>
                                <h2 className="mb-4">商品一覧</h2>
                                <ShopProductsDisplay productsData={productsData} displayStyle={displayStyle} />
                            </Col>
                        </Row>
                    </>
                ) : content === 'about' ? (<>
                    <Row className="mb-2">
                        <h2 className="mb-3">About</h2>
                    </Row>
                    <Row className="mb-3">
                        <Col className=''>
                            <p><strong>お店について</strong>: {shop.description}</p>
                            <p><strong>営業時間</strong>: {shop.openingHours}</p>
                            <p><strong>住所</strong>: 〒{shop.postalCode} {shop.prefecture} {shop.city} {shop.streetAddress} {shop.buildingName} </p>
                        </Col>
                        <Col>
                            <GoogleMapsEmbed 
                                shopId={shopId} 
                                initialEmbedSrc={shop.googleMapsEmbedSrc}
                                showEditButton={showEditButton}
                            />
                        </Col>
                    </Row>
                </>) : (
                    <Row className="mb-3">
                        <Col>
                            <h2 className="mb-4">Contact</h2>
                            <p><strong>電話番号</strong>: {shop.phoneNumber}</p>
                            <p><strong>メールアドレス</strong>: {shop.email}</p>
                        </Col>
                    </Row>
                )}

                <Row className="justify-content-center mb-3">
                    <ShopSnsLinks snsUrls={snsUrls} iconSize={iconSize} iconColors={iconColors} />
                </Row>
            </Container>
        </div>
    );
};

export default ShopHomeScreen;