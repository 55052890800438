// TagsManager.js
import React, { useState } from 'react';
import { Button, Modal, Form, Table, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import {
    useGetTagsQuery,
    useCreateTagMutation,
    useDeleteTagMutation,
    useUpdateTagMutation,
} from '../../slices/tagsApiSlice';
import { formatDate } from '../../utils/dateUtils';

const TagListScreen = () => {
    const [showModal, setShowModal] = useState(false);
    const [currentTag, setCurrentTag] = useState({ id: null, name: '' });

    const { data: tags, isLoading, isError, refetch } = useGetTagsQuery();
    const [createTag] = useCreateTagMutation();
    const [deleteTag] = useDeleteTagMutation();
    const [updateTag] = useUpdateTagMutation();

    const handleShowModal = (tag, event) => {
        event.stopPropagation();
        setCurrentTag(tag);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentTag({ id: null, name: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!currentTag.name) {
            toast.error('タグ名を入力してください');
            return;
        }
    
        try {
            if (currentTag.id) {
                await updateTag({ id: currentTag.id, name: currentTag.name }).unwrap();
                toast.success('タグの更新に成功しました');
            } else {
                await createTag({ name: currentTag.name }).unwrap();
                toast.success('タグの作成に成功しました');
            }
            refetch();
            handleCloseModal();
        } catch (err) {
            toast.error(err?.data?.message || err.message || 'エラーが発生しました');
        }
    };
    

    const deleteHandler = async (id, event) => {
        event.stopPropagation();
        if (window.confirm('Are you sure you want to delete this tag?')) {
            try {
                await deleteTag(id);
                toast.success('Tag deleted successfully');
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error!</div>;

    return (
        <Container>
            <h1>タグ管理画面</h1>

            <Button className='my-4' variant="primary" onClick={(e) => handleShowModal({ id: null, name: '' }, e)}>
                新規タグを作成
            </Button>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentTag.id ? 'Edit Tag' : 'Add Tag'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Tag Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter tag name"
                                value={currentTag.name}
                                onChange={(e) => setCurrentTag({ ...currentTag, name: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button className='mx-2' variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th style={{ width: '5%' }}>#</th>
                        <th style={{ width: '50%' }}>Name</th>
                        <th style={{ width: '10%' }}>商品数</th>
                        <th style={{ width: '23%' }}>作成日</th>
                        <th style={{ width: '12%' }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {tags.map((tag, index) => (
                        <LinkContainer to={`/tag/${tag.id}/edit`} style={{ cursor: 'default' }}>
                            <tr key={tag.id}>
                                <td>{index + 1}</td>
                                <td>{tag.name}</td>
                                <td>{tag.productCount}</td>
                                <td>{formatDate(tag.createdAt)}</td>
                                <td>
                                    
                                        <Button variant='primary' className='btn-sm mx-2' onClick={(e) => handleShowModal(tag, e)}>
                                            <FaEdit />
                                        </Button>
                                
                                    <Button 
                                        variant='danger' 
                                        className='btn-sm' 
                                        onClick={(e) => deleteHandler(tag.id, e)} // イベントオブジェクトを渡す
                                    >
                                        <FaTrash style={{ color: 'white' }} />
                                    </Button>
                                </td>
                            </tr>
                        </LinkContainer>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}

export default TagListScreen;
