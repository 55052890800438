// components/SnsLinks.js
import React from 'react';
import { Col } from 'react-bootstrap';
import { FaInstagram, FaFacebook, FaTwitter, FaLine } from 'react-icons/fa';

const ShopSnsLinks = ({ snsUrls, iconSize, iconColors }) => {
    return (
        <>
            {Object.entries(snsUrls).map(([name, { url }]) => url && (
                <Col md="auto" key={name}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        {name === 'instagram' && <FaInstagram size={iconSize} color={iconColors.instagram} />}
                        {name === 'facebook' && <FaFacebook size={iconSize} color={iconColors.facebook} />}
                        {name === 'twitter' && <FaTwitter size={iconSize} color={iconColors.twitter} />}
                        {name === 'line' && <FaLine size={iconSize} color={iconColors.line} />}
                    </a>
                </Col>
            ))}
        </>
    );
};

export default React.memo(ShopSnsLinks);
