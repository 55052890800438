// src/features/apiSlice.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
  reducerPath: 'api',
  tagTypes: ['Product'], // タグタイプを定義
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://lwgq7zzcw0.execute-api.ap-northeast-1.amazonaws.com/prod',
    prepareHeaders: (headers) => {
      headers.set('Accept', 'application/json'); // JSON形式のレスポンスを期待
      return headers;
    },
  }),
  endpoints: (builder) => ({
    convertImage: builder.mutation({
      query: (file) => ({
        url: 'convert',
        method: 'POST',
        headers: {
          'Content-Type': file.type, // 'image/png' または 'image/webp'
        },
        body: file, // バイナリデータとして送信
      }),
      transformResponse: (response) => response.image, // レスポンスから'image'プロパティを抽出
    }),
    // 他のエンドポイント...
  }),
});

export const { useConvertImageMutation } = apiSlice;