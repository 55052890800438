import { Modal, Button, Table } from 'react-bootstrap';
import { useGetShopBankAccountQuery } from '../slices/balanceApiSlice';

const WithdrawalModal = ({ show, handleClose, withdrawal, onComplete, onReject }) => {
  const { data: bankAccount, isLoading, error } = useGetShopBankAccountQuery(withdrawal?.shopId);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>銀行情報</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? <p>Loading...</p> : error ? <p>Error: {error.message}</p> : (
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td>銀行名</td>
                <td>{bankAccount?.bankName}</td>
              </tr>
              <tr>
                <td>支店名</td>
                <td>{bankAccount?.branchName}</td>
              </tr>
              <tr>
                <td>口座番号</td>
                <td>{bankAccount?.accountNumber}</td>
              </tr>
              <tr>
                <td>口座名義</td>
                <td>{bankAccount?.accountHolderName}</td>
              </tr>
            </tbody>
          </Table>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          キャンセル
        </Button>
        <Button variant="success" onClick={onComplete} disabled={withdrawal.status !== "pending" || isLoading || error}>
          振り込み完了
        </Button>
        <Button variant="danger" onClick={onReject} disabled={withdrawal.status !== "pending" || isLoading || error}>
          振り込み失敗
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WithdrawalModal;
