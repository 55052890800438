import { apiSlice } from './apiSlice';
import { ADMINS_URL } from '../constants/constants';

export const adminsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        loginAdmin: builder.mutation({
            query: (data) => ({
                url: `${ADMINS_URL}/login`,
                method: 'POST',
                body: data,
            }),
        }),
        logoutAdmin: builder.mutation({
            query: () => ({
                url: `${ADMINS_URL}/logout`,
                method: 'POST',
            }),
        }),
    }),
});

export const {
    useLoginAdminMutation,
    useLogoutAdminMutation,
} = adminsApiSlice;