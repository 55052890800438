import { apiSlice } from './apiSlice';
import { BALANCE_URL } from '../constants/constants';

export const balanceApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMyBalance: builder.query({
            query: () => `${BALANCE_URL}/mine`,
            providesTags: ['Balance'],
        }),
        getShopBalance: builder.query({
            query: (shopId) => `${BALANCE_URL}/shop/${shopId}`,
        }),
        getShopBankAccount: builder.query({
            query: (shopId) => `${BALANCE_URL}/shop/${shopId}/bank-account`,
        }),
        getAllWithdrawals: builder.query({
            query: () => `${BALANCE_URL}/withdrawals`,
            providesTags: ['Withdrawals'],
        }),
        createWithdrawal: builder.mutation({
            query: ({amount}) => ({
                url: `${BALANCE_URL}/withdrawals`,
                method: 'POST',
                body: amount,
            }),
            invalidatesTags: ['Balance'],
        }),
        getShopWithdrawals: builder.query({
            query: (shopId) => `${BALANCE_URL}/withdrawals/shop/${shopId}`,
            providesTags: ['Withdrawals'],
        }),
        completeWithdrawal: builder.mutation({
            query: ({ id }) => ({
                url: `${BALANCE_URL}/withdrawals/${id}/complete`,
                method: 'PUT',
            }),
            invalidatesTags: ['Balance', 'Withdrawals'],
        }),
        rejectWithdrawal: builder.mutation({
            query: ({ id }) => ({
                url: `${BALANCE_URL}/withdrawals/${id}/reject`,
                method: 'PUT',
            }),
            invalidatesTags: ['Balance', 'Withdrawals'],
        }),
        registerBankAccount: builder.mutation({
            query: (bankAccount) => ({
                url: `${BALANCE_URL}/bank-account`,
                method: 'POST',
                body: { bankAccount },
            }),
            invalidatesTags: ['Balance'],
        }),
    }),
});

export const { 
    useGetMyBalanceQuery,
    useGetShopBalanceQuery,
    useGetShopBankAccountQuery,
    useGetAllWithdrawalsQuery,
    useCreateWithdrawalMutation,
    useGetShopWithdrawalsQuery,
    useCompleteWithdrawalMutation,
    useRejectWithdrawalMutation,
    useRegisterBankAccountMutation,
}  = balanceApiSlice;