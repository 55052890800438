import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import store from './store';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/bootstrap.custom.css';
// import './assets/styles/index.css';
import './assets/scss/main.scss'; 
import App from './App';
import reportWebVitals from './reportWebVitals';
import AdminRoute from './components/Route/AdminRoute';
import NotificationScreen from './screens/NotificationScreen';

import ShopHomeScreen from './screens/ShopHomeScreen';

import OrderListScreen from './screens/admin/OrderListScreen';
import ProductListScreen from './screens/admin/ProductListScreen';
import ProductEditScreen from './screens/shop/ProductEditScreen';
import UserListScreen from './screens/admin/UserListScreen';
import UserEditScreen from './screens/admin/UserEditScreen';
import WithdrawalListScreen from './screens/admin/WithdrawalListScreen';
import AdminLoginScreen from './screens/admin/AdminLoginScreen';

import TagListScreen from './screens/admin/TagListScreen';
import TagEditScreen from './screens/admin/TagEditScreen';
import AdminOrganizationScreen from './screens/organization/AdminOrganizationScreen';

import ShopManagementScreen from './screens/admin/ShopManagementScreen';
import ShopOwnerInfoScreen from './screens/admin/ShopOwnerInfoScreen';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route path='/shop/:slug' element={<ShopHomeScreen />} />
      <Route path='/shop/:slug/about' element={<ShopHomeScreen />} />
      <Route path='/shop/:slug/contact' element={<ShopHomeScreen />} />
      <Route path='/notifications' element={<NotificationScreen />} />
      <Route path='/login' element={<AdminLoginScreen />} />

      <Route  path='' element={<AdminRoute />}>
        <Route  path='/orderlist' element={<OrderListScreen />} />
        <Route  path='/productlist' element={<ProductListScreen />} />
        <Route  path='/productlist/:pageNumber' element={<ProductListScreen />} />
        <Route  path='/product/:id/edit' element={<ProductEditScreen />} />
        <Route  path='/userlist' element={<UserListScreen />} />
        <Route  path='/user/:id/edit' element={<UserEditScreen />} />
        <Route  path='/taglist' element={<TagListScreen />} />
        <Route  path='/tag/:id/edit' element={<TagEditScreen />} />
        <Route path='/organizations' element={<AdminOrganizationScreen />} />
        <Route  path='/withdrawals' element={<WithdrawalListScreen />} />
        <Route path='/' element={<ShopManagementScreen />} />
        <Route path='/shop-owner/:id' element={<ShopOwnerInfoScreen />} />
      </Route>
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();