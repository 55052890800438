import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { DeviceProvider } from './context/DeviceContext';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import Footer from './components/Footer';

const App = () => {
  const location = useLocation();

  return (
    <DeviceProvider>
      <Header />
      <main className='py-3'>
        <Outlet />
      </main>
      <Footer />
      <ToastContainer />
    </DeviceProvider>
  )
};

export default App;
