import React, { useState, useMemo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useGetShopsQuery } from '../../slices/shopsApiSlice';
import { useUpdateShopMutation, useDeleteShopMutation } from '../../slices/shopsApiSlice';
import { useRegisterShopOwnerMutation } from '../../slices/shopOwnersApiSlice';
import { useGetAreasQuery } from '../../slices/areasApiSlice';
import Tabs from '../../components/Tabs';
import { Container, Row, Col, InputGroup, FormControl, Button, Table, Spinner, Alert } from 'react-bootstrap';
import { FaSearch, FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import ShopForm from '../../components/ShopForm';
import { toast } from 'react-toastify';

const ShopManagementScreen = () => {
  const { data: shops, isError, isLoading } = useGetShopsQuery();
  const { data: areasData, isError: areasError, isLoading: areasLoading } = useGetAreasQuery();
  const [registerShopOwner] = useRegisterShopOwnerMutation();
  const [updateShop] = useUpdateShopMutation();
  const [deleteShop] = useDeleteShopMutation();

  const [selectedArea, setSelectedArea] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [showFormModal, setShowFormModal] = useState(false);
  const [editingShop, setEditingShop] = useState(null);

  const tabsHandler = (value) => setSelectedArea(value);

  const areaTabs = useMemo(() => {
    if (!areasData) return [];
    return [
        { label: 'すべて', value: 'all', ariaSelected: selectedArea === 'all' },
        ...areasData
            .filter(area => area.id !== 1) // 必要であればフィルタリング
            .map(area => ({
                label: area.name,
                value: String(area.id), // ID を文字列として設定
                ariaSelected: selectedArea === String(area.id),
            }))
    ];
}, [selectedArea, areasData]);

  const filteredAndSortedShops = useMemo(() => {
    const filteredByArea = selectedArea === 'all' ? shops : shops?.filter((shop) => shop.areaId === Number(selectedArea));
    const filteredByName = filteredByArea?.filter((shop) => shop.name.toLowerCase().includes(searchTerm.toLowerCase()));

    return filteredByName?.sort((a, b) => a.name.localeCompare(b.name));
  }, [shops, selectedArea, searchTerm]);

  const groupedShops = useMemo(() => {
    if (!filteredAndSortedShops || filteredAndSortedShops.length === 0) {
      return {};
    }

    return filteredAndSortedShops.reduce((groups, shop) => {
      const letter = shop.name[0].toUpperCase();
      if (!/^[A-Z]$/.test(letter)) {
        if (!groups['その他']) {
          groups['その他'] = [];
        }
        groups['その他'].push(shop);
      } else {
        if (!groups[letter]) {
          groups[letter] = [];
        }
        groups[letter].push(shop);
      }
      return groups;
    }, {});
  }, [filteredAndSortedShops]);

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddShop = () => {
    setEditingShop(null);
    setShowFormModal(true);
  };

  const handleEditShop = (shop) => {
    setEditingShop(shop);
    setShowFormModal(true);
  };

  const handleDeleteShop = async (shopId) => {
    if (window.confirm('本当にこのショップを削除しますか？この操作は元に戻せません。')) {
      try {
        await deleteShop(shopId).unwrap();
        toast.success('ショップを削除しました');
      } catch (error) {
        toast.error(error.data?.message || 'ショップの削除に失敗しました');
      }
    }
  };

  const handleFormSubmit = async (shopData) => {
    try {
      if (editingShop) {
        await updateShop(shopData).unwrap();
        toast.success('ショップを更新しました');
      } else {
        // 変更: registerShopOwnerを使用してショップとオーナーを同時に追加
        const { ownerName, ownerEmail, ownerPassword, ...shopDetails } = shopData;
        await registerShopOwner({
          name: ownerName,
          email: ownerEmail,
          password: ownerPassword,
          shopName: shopDetails.name,
          reading: shopDetails.slug,
          areaId: shopDetails.areaId,
        }).unwrap();
        toast.success('ショップとオーナーを追加しました');
      }
      setShowFormModal(false);
      setEditingShop(null);
    } catch (error) {
      toast.error(error.data?.message || 'ショップの追加・更新に失敗しました');
    }
  };

  if (isLoading) return <Spinner animation="border" className="m-5" />;
  if (isError) return (
    <Container>
      <Alert variant="danger" className="m-5">エラーが発生しました。</Alert>
    </Container>
  );

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <h1>ショップ管理</h1>
          <Button variant="primary" className="mt-3" onClick={handleAddShop}>
            <FaPlus /> ショップ追加
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <InputGroup>
            <InputGroup.Text>
              <FaSearch />
            </InputGroup.Text>
            <FormControl
              placeholder="ショップ名を検索"
              value={searchTerm}
              onChange={searchHandler}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Tabs tabs={areaTabs} onClick={tabsHandler} selectedValue={selectedArea} />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <nav className="shop-nav mb-4">
            <ul className="list-inline">
              {Object.keys(groupedShops).map((letter) => (
                <li className='list-inline-item me-3' key={letter}>
                  <a href={`#${letter}`}>{letter}</a>
                </li>
              ))}
            </ul>
          </nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>名前</th>
                <th>スラッグ</th>
                <th>エリア</th>
                <th>アクション</th>
              </tr>
            </thead>
            <tbody>
              {shops.map((shop) => (
                <tr key={shop.id}>
                  <td>{shop.id}</td>
                  <td>
                      {shop.name}
                  </td>
                  <td>{shop.slug}</td>
                  <td>{areasData?.find((area) => area.id === shop.areaId)?.name}</td>
                  <td>
                    <NavLink to={`/shop-owner/${shop.id}`} className="me-2">
                      <Button variant="info" size="sm">
                        オーナー
                      </Button>
                    </NavLink>
                    <Button variant="info" size="sm" className="me-2" onClick={() => handleEditShop(shop)}>
                      <FaEdit /> 編集
                    </Button>
                    <NavLink to={`/shop/${shop.slug}`} className="me-2">
                      <Button variant="info" size="sm">
                        HP
                      </Button>
                    </NavLink>
                    <Button variant="danger" size="sm" onClick={() => handleDeleteShop(shop.id)}>
                      <FaTrash /> 削除
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <ShopForm
        show={showFormModal}
        handleClose={() => { setShowFormModal(false); setEditingShop(null); }}
        onSubmit={handleFormSubmit}
        shop={editingShop}
        areas={areasData || []}
        isAdding={!editingShop}
      />
    </Container>
  );
};

export default ShopManagementScreen;